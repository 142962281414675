<template>
  <div>
    <menu-list :menus="menus" :group-menus="menusGroup" />
  </div>
</template>

<script>
import MenuList from "../../views/MenuList";

export default {
  components: { MenuList },
  name: "App",
  data: () => ({
    menus: [
    ],
    menusGroup: [
      { title: "登録", icon: "mdi-clock-outline", functionid: "RG0000", subMenu:[
        { title: "着完登録", icon: "mdi-barcode-scan", url: "/register", functionid: "RG0001" },
        { title: "着完登録(カンバン)", icon: "mdi-barcode-scan", url: "/register-kanban", functionid: "RG0003" },
        { title: "実績登録(TSV一括)", icon: "mdi-file-import", url: "/linkage", functionid: "RG0002" },
        { title: "工数報告(モック)", icon: "mdi-note-edit-outline", url: "/work-management", functionid: "RG0004" },
      ]},
      { title: "計画", icon: "mdi-calendar-text", functionid: "PL0000", subMenu:[
        { title: "計画取込み", icon: "mdi-upload", url: "/plan", functionid: "PL0001" },
        { title: "資源ディスパッチ登録(TSV一括)", icon: "mdi-clipboard-account", url: "/dispatch/upload", functionid: "PL0006" },
        { title: "計画/実績 表示", icon: "mdi-file-table-box-multiple-outline", url: "/planResultAnalytics", functionid: "PL0003" },
        { title: "オーダーガントチャート", icon: "mdi-chart-gantt", url: "/gantt", functionid: "PL0004" },
        { title: "工程ガントチャート", icon: "mdi-chart-timeline", url: "/resourceGantt", functionid: "PL0005" },
      ]},
      { title: "進捗", icon: "mdi-progress-helper", functionid: "PR0000", subMenu:[
        { title: "ラインアンドン", icon: "mdi-numeric", url: "/product", functionid: "PR0001" },
        { title: "個別生産アンドン", icon: "mdi-numeric", url: "/job-order-product", functionid: "PR0003" },
        { title: "仕掛在庫表示", icon: "mdi-cube-off-outline", url: "/wipInventory", functionid: "PR0002" },
      ]},
      { title: "品質", icon: "mdi-quality-high", functionid: "QU0000", subMenu:[
        { title: "品質アンドン", icon: "mdi-numeric", url: "/qualityAndon", functionid: "QU0001" },
        { title: "品質プロファイル", icon: "mdi-feature-search", url: "/quality-profile", functionid: "QU0002" },
        { title: "品目係数設定", icon: "mdi-table-cog", url: "/item-coefficient", functionid: "QU0003" },
        { title: "品質設定", icon: "mdi-cog-outline", url: "/quality", functionid: "QU0004" },
      ]},
      { title: "履歴", icon: "mdi-history", functionid: "HS0000", subMenu:[
        { title: "製品プロファイル", icon: "mdi-feature-search", url: "/product-profile", functionid: "HS0001" },
        { title: "製品プロファイル(編集)", icon: "mdi-feature-search", url: "/product-profile-edit", functionid: "HS0005" },
        { title: "工程リソース登録/参照", icon: "mdi-attachment", url: "/autoResource", functionid: "HS0002" },
        { title: "測定結果記録", icon: "mdi-auto-download", url: "/inspection", functionid: "HS0004" },
        { title: "トレサビ情報編集", icon: "mdi-database-search", url: "/trace-edit-search", functionid: "HS0006" },
      ]},
      { title: "分析", icon: "mdi-chart-box-outline", functionid: "AN0000", subMenu:[
        { title: "リードタイム分析", icon: "mdi-archive-clock-outline", url: "/leadTimeAnalytics", functionid: "AN0001" },
        { title: "ウォーターフォール分析", icon: "mdi-chart-line", url: "/waterFallAnalytics", functionid: "AN0002" },
        { title: "TT・CT分析", icon: "mdi-chart-bar", url: "/tactcycle", functionid: "AN0003" },
        { title: "待ち分析", icon: "mdi-chart-bar", url: "/waitAnalytics", functionid: "AN0005" },
        { title: "METABASE", icon: "mdi-chart-areaspline-variant", url: "/metabase", functionid: "AN0004" },
      ]},
      { title: "設計", icon: "mdi-notebook-edit", functionid: "DS0000", subMenu:[
        { title: "工程設計", icon: "mdi-notebook-edit", functionid: "DS1000", subMenu:[
          { title: "工程グループ", icon: "mdi-group", url: "/process-group", functionid: "DS1001" },
          { title: "工程", icon: "mdi-database", url: "/process", functionid: "DS1002" },
          { title: "工程構造設計", icon: "mdi-graph", url: "/lineformation", functionid: "DS1003" },
        ]},
        { title: "端末画面設計", icon: "mdi-console", functionid: "DS2000", subMenu:[
          { title: "画面管理", icon: "mdi-format-list-bulleted", url: "/processResource", functionid: "DS2001" },
          { title: "工程画面設定", icon: "mdi-format-line-style", url: "/processResourceManagement", functionid: "DS2002" },
          { title: "区分ID", icon: "mdi-identifier", url: "/resourceid", functionid: "DS2003" },
        ]},
        // { title: "MESメニュー設計(未)", icon: "mdi-menu", url: "/", functionid: "DS0001" },
      ]},
      { title: "マスタ管理", icon: "mdi-database-outline", functionid: "MS0000",subMenu:[
        { title: "アカウント", icon: "mdi-account-key", url: "/account", functionid: "MS0001" },
        { title: "オペレータ", icon: "mdi-account-hard-hat", url: "/operator", functionid: "MS0002" },
        { title: "権限グループ", icon: "mdi-key-chain", url: "/authority", functionid: "MS0003" },
        { title: "データアクセス組織", icon: "mdi-domain", url: "/company", functionid: "MS0004" },
        { title: "グルーピング", icon: "mdi-group", url: "/group", functionid: "MS0005" },
        { title: "オンラインチップ", icon: "mdi-tooltip-question-outline", url: "/onlinetip", functionid: "MS0006" },
        { title: "部署/役割", icon: "mdi-account-multiple", url: "/department", functionid: "MS0007" },
        { title: "資源ディスパッチ分類", icon: "mdi-table-column-plus-after", url: "/dispatch", functionid: "MS0008" },
        { title: "シリアルNo区分", icon: "mdi-numeric", url: "/serialNumbering", functionid: "MS0009" },
      ]},
      { title: "オプション", icon: "mdi-cog", functionid: "OP0000", subMenu:[
        { title: "エラーメール通知設定", icon: "mdi-email-alert", url: "/mail", functionid: "OP2003" },
        { title: "ランプ管理", icon: "mdi-lightbulb", functionid: "OP1000", subMenu:[
          { title: "スマートランプ", icon: "mdi-lightbulb-on-10", url: "/lamp", functionid: "OP1001" },
          { title: "ランプ制御マスタ", icon: "mdi-chip", url: "/lampController", functionid: "OP1002" },
        ]},
        { title: "工数連携", icon: "mdi-application-cog-outline", functionid: "OP2000", subMenu:[
          { title: "標準検査設定", icon: "mdi-pencil", url: "/standard", functionid: "OP2001" },
          { title: "タクトタイム設定", icon: "mdi-clock-outline", url: "/tact", functionid: "OP2002" },
        ]},
        { title: "ラズパイ", icon: "mdi-raspberry-pi", functionid: "OP3000", subMenu:[
          { title: "着完登録ラズパイ", icon: "mdi-group", url: "/raspberyProcess", functionid: "OP3001" },
        ]},
        { title: "カンバン印刷", icon: "mdi-printer", functionid: "OP4000", subMenu:[
          { title: "カンバン品目", icon: "mdi-printer-pos-cog", url: "/kanban", functionid: "OP4001" },
          { title: "カンバン印刷工程", icon: "mdi-database", url: "/kanban-print-process", functionid: "OP4002" },
        ]},
        { title: "製品ラベル印刷", icon: "mdi-printer-outline", functionid: "OP5000", subMenu:[
          { title: "製品ラベル品目", icon: "mdi-printer-pos-cog", url: "/product-info", functionid: "OP5001" },
          { title: "製品ラベル印刷工程", icon: "mdi-database", url: "/product-print-process", functionid: "OP5002" },
        ]},
      ]},
    ],
  }),
};
</script>