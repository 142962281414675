import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import("../views/LoginView.vue"), 
    meta: {
      layout: 'login'
    }
  },
  {
    path: '/home',
    name: 'all',
    redirect: '/',
  },
  {
    path: "/",
    name: "mes-home",
    component: () => import("../views/MesHomeView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/MesRegisterView.vue"),
  },
  {
    path: "/register-kanban",
    name: "register-kanban",
    component: () => import("../views/MesRegisterKanbanView.vue"),
  },
  {
    path: "/work-management",
    name: "work-management",
    component: () => import("../views/WorkManagementView.vue"),
  },
  {
    path: "/linkage",
    name: "linkage",
    component: () => import("../views/MesLinkageView.vue"),
  },
  {
      path: '/product-profile',
      name: 'product-profile',
      component: () => import("../views/ProductProfileView.vue"),
      props: true,
  },
  {
      path: '/product-profile-detail/:serial',
      name: 'product-profile-detail',
      component: () => import("../views/ProductProfileDetailView.vue"),
      props: true,
  },
  {
    path: '/product-profile-edit',
    name: 'product-profile-edit',
    component: () => import("../views/ProductProfileEditView.vue"),
    props: true,
  },
  {
    path: '/product-profile-detail-edit/:serial',
    name: 'product-profile-detail-edit',
    component: () => import("../views/ProductProfileDetailEditView.vue"),
    props: true,
  },
  {
      path: '/quality-profile',
      name: 'quality-profile',
      component: () => import("../views/QualityProfileView.vue"),
      props: true,
  },
  {
    path: "/history",
    name: "history",
    component: () => import("../views/MesHistoryView.vue"),
  },
  {
    path: "/download",
    name: "download",
    component: () => import("../views/MesDownloadView.vue"),
  },
  {
    path: "/process-group",
    name: "process-group",
    component: () => import("../views/ProcessGroupMasterView.vue"),
  },
  {
    path: "/process",
    name: "process",
    component: () => import("../views/ProcessMasterView.vue"),
  },
  {
    path: "/operator",
    name: "operator",
    component: () => import("../views/OperatorMasterView.vue"),
  },
  {
    path: "/company",
    name: "company",
    component: () => import("../views/CompanyMasterView.vue"),
  },
  {
    path: "/standard",
    name: "standard",
    component: () => import("../views/StandardMasterView.vue"),
  },
  {
    path: "/tact",
    name: "tact",
    component: () => import("../views/TactMasterView.vue"),
  },
  {
    path: "/plan",
    name: "plan",
    component: () => import("../views/PlanRegisterView.vue"),
  },
  {
    path: "/group",
    name: "group",
    component: () => import("../views/GroupView.vue"),
  },
  {
    path: "/lineformation",
    name: "lineformation",
    component: () => import("../views/LineFormationMasterView.vue"),
  },
  {
      path: '/product',
      name: 'product',
      component: () => import("../views/MesAndonView.vue"), 
  },
  {
      path: '/product/*',
      name: 'product-process',
      component: () => import("../views/MesAndonView.vue"), 
  },
  {
      path: '/job-order-product',
      name: 'job-order-product',
      component: () => import("../views/MesIndividualAndonView.vue"), 
  },
  {
      path: '/job-order-product/*',
      name: 'job-order-product-process',
      component: () => import("../views/MesIndividualAndonView.vue"), 
  },
  {
      path: '/qualityAndon',
      name: 'quality-andon',
      component: () => import("../views/QualityAndonView.vue"), 
  },
  {
      path: '/qualityAndon/*',
      name: 'quality-andon-process',
      component: () => import("../views/QualityAndonView.vue"), 
  },
  {
    path: "/planResultAnalytics",
    name: "planResultAnalytics",
    component: () => import("../views/PlanResultAnalyticsView.vue"),
  },
  {
    path: "/planResultAnalytics/*",
    name: "planResultAnalytics-process",
    component: () => import("../views/PlanResultAnalyticsView.vue"),
  },
  {
    path: "/lamp",
    name: "lamp",
    component: () => import("../views/LampMasterView.vue"),
  },
  {
    path: "/lampController",
    name: "lampController",
    component: () => import("../views/LampControllerMasterView.vue"),
  },
  {
    path: "/waterfall",
    name: "waterfall",
    component: () => import("../views/WaterFallDownloadView.vue"),
  },
  {
    path: "/waterfallanalytics",
    name: "waterfall-analytics",
    component: () => import("../views/WaterFallAnalyticsView.vue"),
  },
  {
    path: "/waitAnalytics",
    name: "wait-analytics",
    component: () => import("../views/WaitAnalyticsView.vue"),
  },
  {
    path: '/tactcycle',
    name: 'tactcycle',
    component: () => import("../views/TactCycleAnalyticsView.vue"), 
  },
  {
    path: "/processResource",
    name: "processResource",
    component: () => import("../views/ProcessResourceMasterView.vue"),
  },
  {
    path: '/processResourceManagement',
    name: 'processResourceManagement',
    component: () => import("../views/ProcessResourceManagementView.vue"), 
    props: true,
  },
  {
    path: '/gantt',
    name: 'gantt',
    component: () => import("../views/MesGanttView.vue"), 
  },
  {
    path: '/resourceGantt',
    name: 'resource-gantt',
    component: () => import("../views/MesResourceGanttView.vue"), 
  },
  {
    path: '/account',
    name: 'account',
    component: () => import("../views/AccountMasterView.vue"), 
  },
  {
    path: '/wipInventory',
    name: 'wip-inventory',
    component: () => import("../views/WipInventoryView.vue"), 
  },
  {
    path: '/leadTimeAnalytics',
    name: 'lead-time-analytics',
    component: () => import("../views/LeadTimeAnalyticsView.vue"), 
  },
  {
    path: '/autoResource',
    name: 'auto-resource',
    component: () => import("../views/AutoResourceView.vue"), 
  },
  {
    path: '/inspection',
    name: 'inspection',
    component: () => import("../views/InspectionView.vue"), 
  },
  {
    path: "/mail",
    name: "mail",
    component: () => import("../views/MailMasterView.vue"),
  },
  {
    path: '/metabase',
    name: 'metabase',
    beforeEnter() {
      window.open("https://bi.mes.fuji.co.jp/dashboard/26?%25E7%259D%2580%25EF%25BC%259Ain%252F%25E5%25AE%258C%25EF%25BC%259Aout=OUT&%25E7%2599%25BB%25E9%258C%25B2%25E6%2597%25A5=past30days", "_blank")
    }
  },
  {
    path: '/authority',
    name: 'authority',
    component: () => import("../views/AuthorityGroupView.vue"), 
  },
  {
    path: '/item-coefficient',
    name: 'item-coefficient',
    component: () => import("../views/ItemCoefficientMasterView.vue"), 
  },
  {
    path: '/quality',
    name: 'quality',
    component: () => import("../views/QualityMasterView.vue"), 
  },
  {
    path: "/resourceid",
    name: "resourceid",
    component: () => import("../views/ResourceIdMasterView.vue"),
  },
  {
    path: "/onlinetip",
    name: "onlinetip",
    component: () => import("../views/OnlineTipMasterView.vue"),
  },
  {
    
    path: "/raspberyProcess",
    name: "raspberyProcess",
    component: () => import("../views/RaspberyProcessView.vue"),
  },
  {
    
    path: "/kanban",
    name: "kanban",
    component: () => import("../views/KanbanSettingView.vue"),
  },
  {
    
    path: "/kanban-print-process",
    name: "kanban-print-process",
    component: () => import("../views/KanbanPrintProcessView.vue"),
  },
  {
    
    path: "/printer",
    name: "printer",
    component: () => import("../views/PrinterView.vue"),
  },
  {
    path: "/department",
    name: "department",
    component: () => import("../views/DepartmentMasterView.vue"),
  },
  {
    path: "/trace-edit-search",
    name: "trace-edit-search",
    component: () => import("../views/TraceEditSearchView.vue"),
  },
  {
    
    path: "/trace-edit/:serial/:processId",
    name: "trace-edit",
    component: () => import("../views/TraceEditView.vue"),
  },
  {
    
    path: "/trace-info/:root/:serial/:processId", // root:製品プロファイルの編集かそうでないかで権限を分ける
    name: "trace-info",
    component: () => import("../views/TraceInfoView.vue"),
  },
  {
    
    path: "/product-info",
    name: "product-info",
    component: () => import("../views/ProductInfoSettingView.vue"),
  },
  {
    
    path: "/product-print-process",
    name: "product-print-process",
    component: () => import("../views/ProductPrintProcessView.vue"),
  },
  {
    path: "/product-print/:orderNo/:itemCode/:serial",
    name: "product-print",
    component: () => import("../components/PrinterProduct.vue"),
  },
  {
    path: "/kanban-print/:orderNo/:itemCode/:serial",
    name: "kanban-print",
    component: () => import("../components/Printer.vue"),
  },
  {
    path: '/dispatch',
    name: 'dispatch',
    component: () => import("../views/DispatchMasterView.vue"), 
  },
  {
    path: '/dispatch/upload',
    name: 'dispatch-upload',
    component: () => import("../views/DispatchBulkUploadView.vue"), 
  },
  {
    path: '/serialNumbering',
    name: 'serialNumbering',
    component: () => import("../views/SerialNumberingMasterView.vue"), 
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // 遷移先のパスを記憶する
  // ただしログアウト時に/loginに飛んだのまで記憶するので、/loginは除外
  if(to.path !== "/login") {
    localStorage.setItem("to.path", to.path);
  }
  next();
})

export default router;
